/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, HTMLAttributes, memo, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createTranslatedNoContentText } from '@hkm/shared/helpers/translatedNoContentTextFactory';
import { createPaginatorProps } from '@hkm/shared/pagination/paginatorPropsFactory';
import classNames from 'classnames';

import { PagingInfo } from '@ac/library-api';
import { AcNoContent } from '@ac/mobile-components/dist/components/no-content';
import { AcNoContentText } from '@ac/mobile-components/dist/components/no-content/acNoContentText';
import { AcTileGroup } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { PaginationParams, PaginationSize } from '@ac/web-components';

import './PaginatedTileList.css';

interface Props extends HTMLAttributes<HTMLDivElement>, Testable {
  noContentTitle: string;
  isLoading?: boolean;
  hasFilters?: boolean;
  onFiltersReset?: () => void;
  paging?: PagingInfo;
  onPageRequest: (params: PaginationParams) => void;
}

const PaginatedTileList: FC<Props> = ({
  children,
  className: classNameProp,
  noContentTitle,
  isLoading,
  hasFilters,
  paging,
  onPageRequest,
  onFiltersReset,
}) => {
  const className = classNames('paginated-tile-list', classNameProp);
  const { t } = useTranslation();

  const noContentText: AcNoContentText = useMemo(
    () => createTranslatedNoContentText(noContentTitle),
    [noContentTitle]
  );

  const isEmpty = !!paging && paging.totalCount === 0 && !isLoading;
  const lastNonLoadingEmptyState = useRef<boolean>(isLoading || isEmpty);
  if (!isLoading) {
    lastNonLoadingEmptyState.current = isEmpty;
  }

  return (
    <ac-flex direction={FlexDirection.column} class={className}>
      <ac-flex
        class="paginated-tile-list-content"
        direction={FlexDirection.column}
      >
        {!isEmpty ? (
          <AcTileGroup>{children}</AcTileGroup>
        ) : (
          <ac-flex
            class="paginated-tile-list-no-content-container"
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.center}
          >
            <AcNoContent
              text={noContentText}
              isFiltered={hasFilters}
              onFiltersReset={onFiltersReset}
            />
          </ac-flex>
        )}
      </ac-flex>

      {!lastNonLoadingEmptyState.current && paging && (
        <ac-flex>
          <ac-pagination
            {...createPaginatorProps(paging, onPageRequest)}
            size={PaginationSize.md}
            translations={{
              perPage: t('PAGINATION.PER_PAGE'),
              goTo: t('PAGINATION.GO_TO'),
              of: t('PAGINATION.OF'),
              items: t('PAGINATION.ITEMS'),
            }}
          />
        </ac-flex>
      )}
    </ac-flex>
  );
};

export default memo(PaginatedTileList);
